import { configureStore, createSlice } from '@reduxjs/toolkit'
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils'
import { fetchAuthSession } from 'aws-amplify/auth';
import { combineReducers } from 'redux'
import propertyReducer from './propertySlice'

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    signUpVerificationMethod: "code",
    // authenticationFlowType: 'USER_SRP_AUTH',
    mandatorySignIn: true,
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      loginWith: {
        email: true,
      },
    }
  },
  API: {
    REST: {
      MyApiGateway: {
        endpoint:"https://api.home-bridge.ca",
        region: 'us-east-1', // replace with your region
        custom_header: async () => {
          const session = await fetchAuthSession();
          return { Authorization: `Bearer ${session.tokens.idToken.toString()}` };
        }
      }
    }
  },
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: {}
  },
  reducers: {
    setAuthenticationUser(state, action) {
      console.log('setAuthenticationUser action dispatched:', action.payload);
      state.user = action.payload.user
      state.isLoading = false;
    },
    setLoading(state, action) {
      console.log('setLoading action dispatched:', action.payload);
      state.isLoading = action.payload;
    }
  }
})

const { setAuthenticationUser } = authenticationSlice.actions

const rootReducer = combineReducers({
  authentication: authenticationSlice.reducer,
  properties: propertyReducer
})

const store = configureStore({
  reducer: rootReducer,
})

async function handleSignIn(data) {
  console.log('handleSignIn called', data);
  try {
    console.log('Fetching auth session...');
    const session = await fetchAuthSession();
    console.log('Auth session fetched:', session);
    if (session?.tokens?.idToken) {
      const user = session.tokens.idToken.payload;
      console.log('Dispatching setAuthenticationUser with:', user);
      store.dispatch(setAuthenticationUser({ user }));
      console.log('setAuthenticationUser dispatched');
      // Navigate to Dashboard after sign in
      if (window.location.pathname === '/login') {
        console.log('Redirecting to dashboard');
        window.location.href = '/';
      }
    } else {
      console.log('No valid session found in handleSignIn');
    }
  } catch (error) {
    console.error('Error in handleSignIn:', error);
  }
}

function handleSignOut({ data, storeState }) {
  console.log('user signed out')
  storeState.dispatch(setAuthenticationUser({ user: {} }))
}

Hub.listen('auth', (data) => {
  const event = data?.payload?.event
  const storeState = store.getState()
  console.log('Hub auth event:', data.payload.event);

  switch (event) {
    case 'signedIn':
      return handleSignIn(data)
    case 'signedOut':
      return handleSignOut({
        storeState
      })
    default:
      return
  }
})

fetchAuthSession()
  .then(session => {
    console.log('Fetching auth session')
    if (session?.tokens?.idToken) {
      const user = session.tokens.idToken.payload;
      store.dispatch(setAuthenticationUser({ user }));
    }
  })
  .catch(err => console.log('No current auth session'));

export default store