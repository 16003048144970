import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

export const fetchProperties = createAsyncThunk(
  'properties/fetchProperties',
  async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();

      const restOperation = get({
        apiName: 'MyApiGateway',
        path: '/properties',
        options: {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        }
      });
      const { body } = await restOperation.response;
      const result = await body.json();
      return result;
    } catch (error) {
      console.error('Error fetching properties:', error);
      throw error;
    }
  }
);

export const fetchPropertyDetails = createAsyncThunk(
  'properties/fetchPropertyDetails',
  async (propertyId, { rejectWithValue }) => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();

      console.log('Fetching property details with token:', token);

      const restOperation = get({
        apiName: 'MyApiGateway',
        path: `/properties/${propertyId}`,
        options: {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        }
      });

      console.log('API request sent, waiting for response...');

      const response = await restOperation.response;
      console.log('Full API response:', response);

      if (!response || !response.body) {
        console.error('Invalid response structure:', response);
        return rejectWithValue('Invalid response structure');
      }

      const result = await response.body.json();
      console.log('API result:', result);

      if (response.statusCode >= 400) {
        console.error('API error:', response.statusCode, result);
        return rejectWithValue(result);
      }

      return result;
    } catch (error) {
      console.error('Error in fetchPropertyDetails:', error);
      return rejectWithValue(error.toString());
    }
  }
);

export const addProperty = createAsyncThunk(
  'properties/addProperty',
  async (propertyData, { rejectWithValue }) => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();

      console.log('Adding property with token:', token);

      const restOperation = post({
        apiName: 'MyApiGateway',
        path: '/properties',
        options: {
          body: propertyData,
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
        }
      });

      console.log('API request sent, waiting for response...');

      const response = await restOperation.response;
      console.log('Full API response:', response);

      if (!response || !response.body) {
        console.error('Invalid response structure:', response);
        return rejectWithValue('Invalid response structure');
      }

      const result = await response.body.json();
      console.log('API result:', result);

      if (response.statusCode >= 400) {
        console.error('API error:', response.statusCode, result);
        return rejectWithValue(result);
      }

      return result;
    } catch (error) {
      console.error('Error in addProperty:', error);
      return rejectWithValue(error.toString());
    }
  }
);

const propertySlice = createSlice({
  name: 'properties',
  initialState: {
    list: [],
    propertyDetails: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchPropertyDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPropertyDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.propertyDetails = action.payload;
      })
      .addCase(fetchPropertyDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addProperty.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list.push(action.payload);
      })
      .addCase(addProperty.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default propertySlice.reducer;