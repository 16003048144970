import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { fetchPropertyDetails } from '../store/propertySlice';

const PropertyDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { propertyDetails, status, error } = useSelector((state) => state.properties);

  useEffect(() => {
    dispatch(fetchPropertyDetails(id));
  }, [dispatch, id]);

  if (status === 'loading') {
    return <Typography>Loading...</Typography>;
  }

  if (status === 'failed') {
    return <Typography>Error: {error}</Typography>;
  }

  if (!propertyDetails) {
    return <Typography>No property details available.</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">{propertyDetails.name}</Typography>
      <Typography variant="subtitle1">Address: {propertyDetails.address}</Typography>
      <Typography variant="h5">Dwellings:</Typography>
      <List>
        {propertyDetails.dwellings.map((dwelling) => (
          <ListItem key={dwelling.id}>
            <ListItemText primary={dwelling.name} secondary={dwelling.type} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default PropertyDetails;