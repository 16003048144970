import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authentication.user);

  console.log('Dashboard rendering, user:', user); // Add this line for debugging

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className='Dashboard'>
      <h1>Welcome to Homebridge</h1>
      <p>Username: {user.email}</p>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/properties')}
      >
        View My Properties
      </Button>
    </div>
  );
};

export default Dashboard;