import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { addProperty } from '../store/propertySlice';

const AddProperty = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(addProperty({ name, address })).unwrap();
      console.log('Property added successfully:', result);
      navigate('/properties');
    } catch (error) {
      console.error('Error adding property:', error);
      // Here you might want to show an error message to the user
    }
  };

  return (
    <div>
      <Typography variant="h4">Add New Property</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Property Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Add Property
        </Button>
      </form>
    </div>
  );
};

export default AddProperty;