import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchProperties } from '../store/propertySlice';

const Properties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list: properties, status, error } = useSelector((state) => state.properties);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProperties());
    }
  }, [status, dispatch]);

  const handleAddProperty = () => {
    navigate('/add-property');
  };

  const handlePropertyClick = (propertyId) => {
    navigate(`/property/${propertyId}`);
  };

  if (status === 'loading') {
    return <Typography>Loading...</Typography>;
  }

  if (status === 'failed') {
    return <Typography>Error: {error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">My Properties</Typography>
      <Button variant="contained" color="primary" onClick={handleAddProperty}>
        Add Property
      </Button>
      <List>
        {properties.map((property) => (
          <ListItem 
            button 
            key={property.id} 
            onClick={() => handlePropertyClick(property.id)}
          >
            <ListItemText primary={property.name} secondary={property.address} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Properties;