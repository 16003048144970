import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Navbar = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authentication.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const handleDashboardClick = () => {
    navigate('/');
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Extract the part of the email before the "@" sign
  const userEmail = user?.email ? user.email.split('@')[0] : '';

  return (
    <AppBar position="static" sx={{ height: '50px' }}>
      <Toolbar sx={{ minHeight: '50px !important', height: '50px' }}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
            <img 
              src="/logo.png" 
              alt="HomeBridge Logo" 
              style={{ height: '32px', marginRight: '5px' }} // Reduced logo height
            />
            <Typography variant="h5"> {/* Increased font size */}
              HomeBridge
            </Typography>
          </Link>
        </Box>
        {user && (
          <>
            <Button color="inherit" onClick={handleDashboardClick}>Dashboard</Button>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem disabled>{userEmail}</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;